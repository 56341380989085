import React from "react"
import {
    Heading,
    VStack,
    StackDivider,
    Center,
} from "@chakra-ui/react";
import Layout from '../components/Layout'
import HostIntro from '../components/HostIntro'
import SubscribeBlock from '../components/SubscribeBlock'
import affeAvatar from '../images/affe.jpg'
import dennyAvatar from '../images/denny.jpg'

export default function About(props) {
    return (
        <Layout>
            <Center>
                <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    spacing={4}
                    w="100%"
                    align="stretch"
                    justify="center"
                >
                    <Heading as="h1" size={['md', 'lg']}>
                        關於我們
                    </Heading>
                    <HostIntro
                        host={{
                            name: 'Affe',
                            avatar: affeAvatar,
                            intro: 'Hi, Affe desu'
                        }}
                    />
                    <HostIntro
                        host={{
                            name: 'Denny',
                            avatar: dennyAvatar,
                            intro: 'Hey, Denny desu'
                        }}
                    />
                    <SubscribeBlock />
                </VStack>
            </Center>
        </Layout>
    );
}
