import React from "react"
import {
    Text,
    Heading,
    Avatar,
    Grid,
    GridItem,
} from "@chakra-ui/react";

export default function HostIntro(props) {
    const { name, avatar, intro } = props.host
    return (
        <Grid
            h="200px"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(5, 1fr)"
            gap={4}
        >
            <GridItem rowSpan={2} colSpan={1}>
                <Avatar
                    name={name}
                    src={avatar}
                />
            </GridItem>
            <GridItem colSpan={4}>
                <Heading as="h2">{name}</Heading>
            </GridItem>
            <GridItem colSpan={4}>
                <Text>{intro}</Text>
            </GridItem>
        </Grid>
    )
}
